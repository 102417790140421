import * as cookieMonster from '../../plugins/cookie-monster/index.js';
const showCookieMonster = () => {
  const cookieBox = document.querySelector('[data-cookiebox]');
  if (cookieBox) {
    cookieMonster.cookieMonster({
      type: 'modal',
      linkUrl: 'https://012grp.co.jp/cookie',
    })
  }
}

showCookieMonster();
